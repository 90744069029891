import {
  MemberConnection,
  PageInfo,
  QueryGetMembersArgs,
  useGetMembersLazyQuery
} from '../../gql/generated';
import { CONTACTS_PAGE_SIZE } from './Contacts.const';
import { useState } from 'react';
import { MembersType } from '../../types';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import { getInvitationStatusForMembers } from '../../apis/invitationAPIs';
import { contactActions } from './contacts.ducks';
import { commonActions } from '../../constants/actions';
import { CONTACT_DATA_ERROR, CONTACT_PAGINATION_ERROR } from '../../constants/toasterMessages';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { selectIsReadOnlyAccess } from '../../reducers/authentication.reducer';

export type GetMembersArgs = QueryGetMembersArgs & {
  onCompleted?: (page: number) => void;
};

export type UseMembersReturnType = {
  getMembers: (getMemberArgs: GetMembersArgs) => void;
  loading: boolean;
  data: {
    members: MembersType[];
    pageInfo?: PageInfo;
  };
};

export const useMembers = (): UseMembersReturnType => {
  const [getMembers, { loading, data, error }] = useGetMembersLazyQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const inviteToWorkshopFlag = useFeatureIsOn('sampark.invite-to-workshop');
  const isReadOnly = useAppSelector(selectIsReadOnlyAccess);

  const {
    emailType: contactEmailType,
    filteredCity,
    filteredState,
    contacts,
    contactsTotalNumber,
    contactsTotalPages
  } = useAppSelector((state) => state.contactsData);

  const { filterForm } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();

  const getMembersFn = (getMemberArgs: GetMembersArgs) => {
    const {
      filter,
      pageNumber = 1,
      pageSize = CONTACTS_PAGE_SIZE,
      search,
      onCompleted,
      sort
    } = getMemberArgs;

    //Remove this once we migrate from redux
    dispatch(contactActions.showLoader());

    let attributes = [];
    let muktType = [];
    let adminAttributes = [];
    let audiences = [];
    let maritalStatus = '';

    const { unverified, ...filterValues } = filterForm?.values ?? { unverified: false };

    if (filterValues) {
      attributes = filterValues?.attributes?.map((attr: any) => attr.value);
      muktType = filterValues?.muktType?.map((type: any) => type.value);
      adminAttributes = filterValues?.adminAttributes?.map((type: any) => type.value);
      audiences = filterValues?.audiences?.map((type: any) => type.value);

      //TODO  FIX this; change it Array or change ui to Radiobutton
      maritalStatus =
        filterValues?.maritalStatus && filterValues?.maritalStatus?.Single ? 'Single' : '';
      maritalStatus =
        filterValues?.maritalStatus && filterValues?.maritalStatus?.Married ? 'Married' : '';
    }

    const contactFilterValues = {
      ...filterValues,
      city: filteredCity || undefined,
      state: filteredState || undefined,
      attributes,
      muktType,
      adminAttributes,
      audiences,
      emailType: contactEmailType ? contactEmailType : undefined,
      contactStatus: unverified ? 'unverified' : undefined,
      maritalStatus
    };

    const currentPage = pageNumber ?? 1;

    void getMembers({
      variables: {
        filter: filter || contactFilterValues,
        pageNumber: currentPage,
        pageSize,
        search: search ? search : undefined,
        sort
      },
      onCompleted: (data) => {
        if (data.getMembers) {
          if (onCompleted) {
            onCompleted(data.getMembers.pageInfo.page);
          }
          updateMembersWithStatus(data.getMembers, currentPage > 1);
        }
      }
    });
  };

  const updateMembersWithStatus = async (data: MemberConnection, append = false) => {
    setIsLoading(true);
    let formatMembers: MembersType[] = [];
    const members = (data.members as MembersType[]) ?? [];
    const memberIds = members.map((contact: MembersType) => contact?._id ?? '').filter(Boolean);

    if (inviteToWorkshopFlag && !isReadOnly) {
      let invitationStatusForMembers: { memberId: string; status: string }[] = [];
      invitationStatusForMembers = await getInvitationStatusForMembers(memberIds);
      formatMembers = members.map((contact: MembersType) => {
        const updatedMember = { ...contact, selected: false };
        // If feature flag is on, add workshopInvitationStatus property to member
        if (inviteToWorkshopFlag && invitationStatusForMembers?.length) {
          const foundInvitationStatusForContact = invitationStatusForMembers.find(
            (member: { memberId: string; status: string }) => member.memberId === updatedMember._id
          );
          if (foundInvitationStatusForContact) {
            updatedMember.workshopInvitationStatus = foundInvitationStatusForContact.status;
          }
        }
        return updatedMember;
      });
    } else {
      formatMembers = members.map((contact: MembersType) => {
        return { ...contact, selected: false };
      });
    }
    setIsLoading(false);
    //Remove this once we migrate from redux
    dispatch(contactActions.hideLoader());
    const membersList = {
      members: formatMembers,
      pageInfo: data.pageInfo
    };
    dispatch(contactActions.setContactsData(membersList, append));
  };

  if (error) {
    dispatch({
      type: commonActions.SHOW_HIDE_TOASTER,
      value: {
        displayToaster: true,
        message:
          (data?.getMembers?.pageInfo?.page || 1) > 1
            ? CONTACT_PAGINATION_ERROR
            : CONTACT_DATA_ERROR,
        type: commonActions.ERROR_TOASTER
      }
    });
  }

  return {
    getMembers: getMembersFn,
    loading: loading || isLoading,
    data: {
      members: contacts,
      pageInfo: {
        __typename: 'PageInfo',
        pages: contactsTotalPages,
        total: contactsTotalNumber,
        limit: CONTACTS_PAGE_SIZE,
        page: data?.getMembers?.pageInfo.page || 1
      }
    }
  };
};
